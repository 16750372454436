<template>
  <div class="v_change_pwd">
    <div class="v-change-title">
      <label class="iconfont icon-fanhui" @click="$router.replace({ name: 'safecenter' })"></label>
      <span>修改登录密码</span>
      <!-- <div class="v-change-line"></div> -->
    </div>
    <div class="v-change-form g-flex-column g-flex-align-center">
      <!-- 手机号 -->
      <div class="v-change-form-item g-flex-column">
       <div class="v-change-form-item-title">
           <span>当前绑定手机号</span>
           <!-- <div class="v-change-line"></div> -->
        </div>
        <van-field v-model="userInfo.mobile" placeholder="请输入当前手机号" readonly/>
      </div>
      <!-- 原密码 -->
      <div class="v-change-form-item g-flex-column">
        <div class="v-change-form-item-title">
           <span>当前密码</span>
           <!-- <div class="v-change-line"></div> -->
        </div>
        <div class="v-look-for-item-pwd g-flex-align-center">
          <van-field v-model="form.old_password" :type="pwdType"  placeholder="请输入当前密码"/>
          <i class="iconfont" :class="pwdType ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeClick"></i>
        </div>
      </div>
      <!-- 新密码 -->
      <div class="v-change-form-item g-flex-column">
       <div class="v-change-form-item-title">
           <span>新密码</span>
           <!-- <div class="v-change-line"></div> -->
        </div>
        <div class="v-look-for-item-pwd g-flex-align-center">
          <van-field v-model="form.new_password" :type="pwdTypeSecond"  placeholder="请输入新密码"/>
          <i class="iconfont" :class="pwdTypeSecond ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeSedondClick"></i>
        </div>
      </div>
      <!-- 确认新密码 -->
      <div class="v-change-form-item g-flex-column">
       <div class="v-change-form-item-title">
           <span>确认密码</span>
           <!-- <div class="v-change-line"></div> -->
        </div>
          <div class="v-look-for-item-pwd g-flex-align-center">
          <van-field v-model="form.newSecondpassword" :type="pwdTypeThird"  placeholder="请再次输入新密码"/>
          <i class="iconfont" :class="pwdTypeThird ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeThirdClick"></i>
        </div>
      </div>
    </div>

    <div class="g-flex-justify-between">
      <operation-button :buttonObj="buttonObj" @clickItem="emitLoginClick"/>
    </div>
  </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
import { apiChangePassword } from '@/utils/api.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        userInfo: this.$global.userInfo,

        pwdType: 'password',
        pwdTypeSecond: 'password',
        pwdTypeThird: 'password',
       
        form: {
          old_password: '',
          new_password: '',
          newSecondpassword: ''
        },
        buttonObj: {
          title: '确认修改',
          claSS: 'c-btn-default',
          event: 'apiChangePasswordHandel',
        }
      }
    },
    methods: {
      async apiChangePasswordHandel() {
        if(!this.form.old_password) return this.$toast('请输入当前密码')
        if(!this.form.new_password) return this.$toast('请输入新密码')
        if(this.form.new_password !== this.form.newSecondpassword) return this.$toast('两次密码输入不一致')
        this.$global.loadingShow = true
        const { success, data } = await apiChangePassword(this.form)
        if(!success) return
        this.$toast(data.msg)
        setTimeout(()=> {
          this.$router.go(-1)
        }, 500)
        console.log(data)
      },

      pwdTypeThirdClick() {
        if(this.pwdTypeThird === 'password') return this.pwdTypeThird = 'text'
        return this.pwdTypeThird = 'password'
      },

      pwdTypeSedondClick() {
        if(this.pwdTypeSecond === 'password') return this.pwdTypeSecond = 'text'
        return this.pwdTypeSecond = 'password'
      },

      pwdTypeClick() {
        if(this.pwdType === 'password') return this.pwdType = 'text'
        return this.pwdType = 'password'
      },

      emitLoginClick(event) {
        if(!event) return
        this[event]()
      },
    }
  }
</script>

<style lang="scss">
.v_change_pwd {
  width: 100%;
  height: 100%;
  overflow: auto;
  // 登录标题
  .v-change-title {
    width: 250px;
    height: 100px;
    font-weight: bold;
    color: $black;
    position: relative;
    // 箭头
    .icon-fanhui {
      position: absolute;
      bottom: 0;
      font-size: 40px;
      font-weight: normal;
      z-index: 99;
      left: 10px;
      color: $main_color;
    }
    span {
      font-size: 32px;
      position: absolute;
      bottom: 0px;
      z-index: 99;
      right: 0;
    }
    .v-change-line {
      position: absolute;
      height: 10px;
      width: 100%;
      background: $main_color;
      bottom: 0;
      z-index: 88;
    }
  }
  // 表单
  .v-change-form {
    padding: 50px 40px 30px 50px;
    .v-change-form-item {
      align-self: stretch;
      .v-change-form-item-title {
        height: 30px;
        position: relative;
        span {
          padding-left: 10px;
          position: absolute;
          font-size: 18px;
          bottom: 0;
          z-index: 99;
          color: $black;
          font-weight: 600;
        }
        .v-change-line {
          position: absolute;
          height: 3px;
          width: 40px;
          background: $main_color;
          bottom: 4px;
          left: 34px;
          z-index: 88;
        }
      }
      // 密码
      .v-look-for-item-pwd {
        position: relative;
        .iconfont {
          position: absolute;
          right: 0;
          font-size: 24px;
          padding: 5px;
        }
      }
      // 设置输入框
      .van-cell {
        padding-left: 10px;
        border-bottom: 1px solid #B5B5B5;
        input {
          &::-ms-input-placeholder {
            color: #B2B2B2;
          }
          &::-webkit-input-placeholder {
            color: #B2B2B2;
          }
        }
      }
      // 验证码
      .v-change-item-code {
        border-bottom: 1px solid #B5B5B5;
        .van-cell {
          border-bottom: 0;
          flex: 1;
        }
        .v-change-code-btn {
          width: 70px;
          height: 30px;
          background: #FED9E1;
          color: $main_color;
          border-radius: 20px;
          font-size: 14px;
        }
      }
      // 登陆密码
      &:nth-of-type(3) {
        .v-change-form-item-title {
          .v-change-line {
            width: 60px;
          }
        }
      }
    }
  }

  .v-change-bottom {
    padding: 10px 30px;
    .v-change-login {
      color: $main_color;
      font-size: 12px;
      padding: 6px;
    }
  }
}
</style>